import React from 'react'
import * as styles from './projectviewer.module.css'
import pic01 from '../images/pic01.jpg'
import embryo from '../images/embryoupload.png'
import cheffu from '../images/cheffu.png'
//expect to pass in value for which project to view
const ProjectViewer = props => {
  return (
    <div className={`${props.enabled ? styles.active : styles.hidden} `}>
      <div
        id="capstone"
        className={`${props.selection === 'capstone' ? styles.active : styles.hidden} `}
      >
        <h3>Web Deployment Platform for In-Vitro Fertilization</h3>
        <p>
          Our team created a web application that allows AMNL labs to deploy
          their embryo classification models and test their accuracy against
          live embryo data. The application allows authorized IVF clinics to
          access the models and assess the accuracy of the models. This
          application can be reused for other AI applications. <br />
          I was responsible for the front-end embryo upload component.
          <br />
          <br />
          <br /> Tech used: React JS Frontend/ Django Backend
        </p>
        <span className="image main">
          <img src={embryo} />
        </span>
      </div>
      <div
        id="climate-gui"
        className={`${
          props.selection === 'climate-gui' ? styles.active : styles.hidden
        } `}
      >
        <h3>Climate Gui</h3>
        <p>
          I designed a graphical user interface for ice analysts to run
          custom-made product-generation scripts using WxPython. This project
          required frequent communication with the client and utilization of
          engineering design principles and practices to isolate the problem and
          provide a solution.
        </p>
      </div>
      <div
        id="cheffu"
        className={`${props.selection === 'cheffu' ? styles.active : styles.hidden} `}
      >
        <h3>Cheffu: Recipe filtering application</h3>
        <p>
        <a
            href="https://github.com/Marinette/Cheffu"
            className="icon fa-github"
          >
           &nbsp; See it on Github
          </a> <br/>
          Our team created a application that suggests recipes to users based on
          user input of available ingredients as well as other considerations
          such as maco/calorie breakdown. We utilized the Spoonacular API to
          fetch existing ingredients. I was in charge of the front-end React
          component of the application.
        </p>
        <span className="image main">
          <img src={cheffu} />
        </span>
      </div>
    </div>
  )
}
export default ProjectViewer
