import PropTypes from 'prop-types'
import React from 'react'
import ProjectViewer from './ProjectViewer.js'

// import pic01 from '../images/pic01.jpg'
// import pic02 from '../images/pic02.jpg'
// import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isProjectsVisible: false,
      selectedProject: '',
    }
    this.onProjectSelected = this.onProjectSelected.bind(this)
    this.onProjectsDeselected = this.onProjectsDeselected.bind(this)
  }

  onProjectSelected(article) {
    this.setState({
      isProjectsVisible: true,
      selectedProject: article,
    })
  }

  onProjectsDeselected() {
    this.setState({
      isProjectsVisible: false,
    })
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          {/* <span className="image main">
            <img src={pic01} alt="" />
          </span> */}
          <p>
            I'm a Computer Engineering newgrad who is looking for new job
            prospects. I have 2 years of experience working with GIS,
            particulary QGIS and ArcGIS. However, I'm always excited to learn
            new things! I am particularly interested in machine learning
            development, particularly when applied to biology. For my
            undergraduate capstone, my team developed a web platform to deploy
            machine learning models for IVF prediction, which I found
            exceedingly interesting.
          </p>
          <p>
            Outside of school/work, I have various hobbies including
            videography, photography, baking & cooking, and playing sports such
            as volleyball and tennis. As of late, I've developed a mechanical
            keyboard obsession. Finally, I love to try new things! For example,
            I'm using Gatsby for the first time to develop this website. :)
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          {/* <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
          <p>
            May 2019 - April 2021 <br /> Software Engineer, Environment and
            Climate Change Canada
          </p>
          <p>
            For my PEY internship at Environment and Climate Change Canada
            (ECCC), I was first in charge of creating scripts to generate the
            Canadian Ice Service Charts (CIS) for Arctic Navigation using
            PyQGIS. <br />
            Afterwards, I created a ArcGIS GUI for CIS to generate various ice
            analysis products using WPF. <br /> I was happy to extend my
            internship into my fourth year of study, where I participate in the creation of the Government of Canada 30 year climatic ice atlases and focused on
            designing a cross-platform, maintainable GUI which allows internal
            CIS users to generate a wider variety of products using WxPython.{' '}
            <br />
            This experience was invaluable as it allowed me to apply engineering
            design skills learned from school and participate in various stages
            of the software design cycle.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}
          <p>
            I have a Bachelor's Degree in Computer Engineering from the
            University of Toronto with a minor in Artificial Intelligence.
            <br />
            <br /> Although I am primarily based in the Toronto, Canada region,
            I am open to relocation.
          </p>
          {close}
        </article>

        <article
          id="projects"
          className={`${this.props.article === 'projects' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}
          <div>
            <ProjectViewer
              enabled={this.state.isProjectsVisible}
              selection={this.state.selectedProject}
            />
            <span>
              <button
                className="project-button"
                onClick={() => this.onProjectSelected('capstone')}
              >
                Web Deployment Platform for In-Vitro Fertilization
              </button>

              <button
                className="project-button"
                onClick={() => this.onProjectSelected('climate-gui')}
              >
                GUI for CIS Climate Analysis
              </button>

              <button
                className="project-button"
                onClick={() => this.onProjectSelected('cheffu')}
              >
                Cheffu: Recipe Finder
              </button>
            </span>
          </div>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form> */}
          <ul className="icons">
            {/* <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li> */}
            <li>
              <a href="https://www.linkedin.com/in/marinette-chen-18b72713a/" className="icon fa-linkedin">
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/Marinette" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
